<template>
  <div>
    <h2 class="title-g">修改密码</h2>
    <el-form
      :model="ruleForm"
      state-icon
      :rules="rules"
      ref="ruleForm"
      label-position="left"
      label-width="100px"
      style="width: 40%; margin: 60px auto"
      hide-required-asterisk
    >
      <el-form-item label="原密码" prop="pass">
        <el-input
          type="password"
          v-model="ruleForm.pass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="oldPass">
        <el-input
          type="password"
          maxlegnth="15"
          v-model="ruleForm.oldPass"
          autocomplete="off"
          placeholder="6~15位英文和数字"
          onKeyUp="value=value.replace(/[\W]/g,'')"
          onafterpaste="value=value.replace(/[\W]/g,'')"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="ruleForm.checkPass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { resetPsd } from "@/api/api";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("请输入不低于6位的新密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.oldPass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      rules: {
        pass: [{ required: true, message: "输入原密码", trigger: "blur" }],
        oldPass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm() {
      resetPsd(this.ruleForm).then((res) => {
        
        if (res.result) {
          sessionStorage.removeItem("Authorization");
          this.$alert("密码修改成功，重新登陆！", {
            type: "success",
            callback: () => {
              this.$router.push({ name: "login" });
            },
          });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>